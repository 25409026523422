import React from 'react';
import Blog from './blog/Blog';

function App() {
  return (
    <Blog />
  );
}

export default App;
